import { useState, useContext } from "react";
import roomheadpic from "../assets/contract.png";
import "../styles/propinfo.css";
import "antd/dist/antd.css";
import pic from "../assets/unnamed.png";
import {
  Input,
  Button,
  Modal,
  Spin,
  Steps,
  Card,
  Checkbox,
  Descriptions,
  Space,
  Table,
} from "antd";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useRef } from "react";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import { PropertyContext } from "../../HB Components/propertyContext/PropertyContext";

export default function Publish({ data1, propname, gstnum }) {
  const {
    basic,
    oldupiid,
    property_update,
    screenimages,
    addToBasic,
    property_aggreid,
    locationValue,
    addToLocations,
    propInput,
    facilities,
    addToFacilities,
    roomDetails,
    addToRoomDetails,
    propImages,
    addToPropImages,
    propImagespath,
    addToPropImagespath,
    profileDetails,
    addToProfile,
    profileImagePath,
    deleteimages,
    gstvarified_details,
    basic_Verifiedbut,
    profile_acVerifiedbut,
    profileupiverified,
    changeschecking,
    idforUpdation,
    textNum,
    logo_img,
    onboardapi,
  } = useContext(PropertyContext);

  const location = useLocation();
  const ref1 = useRef(null);
  let data1cont = [];
  let frontofficedata = [];
  let Gmdata = [];
  let Reservation = [];
  let finance_manager = [];
  let othercontactdetail = [];
  const navigate = useNavigate();
  let timer1;

  const [salesdata, setsalesdata] = useState([]);
  const [frontoffdata, setfrontoffdata] = useState([]);
  const [Gmdataarr, setGmdataarr] = useState([]);
  const [financemanager, setFinancemanager] = useState([]);
  const [othersmanager, setOthersmanager] = useState([]);
  const [Reservationdata, setreservationdata] = useState([]);
  const [prname, setprname] = useState("");
  const [gstnm, setgstnm] = useState("");
  const [verification, setVerification] = useState(0);
  const [verificationid, setVerificationid] = useState();

  const [contactdet, setcontacdet] = useState([]);
  const [openmodal, setopenmodal] = useState(false);
  const [openerror, setopenerror] = useState(false);
  const [termsconditions, setTermsconditions] = useState(false);
  const [sucess, setsucess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openmodalsuccess, setopenmodalsuccess] = useState(false);
  const [updateproperty, setupdateproperty] = useState([]);
  const [errormessagearr, setmessagearr] = useState("");
  const [open, setOpen] = useState(false);
  const [openotpverify, setopenotpverify] = useState(false);
  const [errormodel, seterrormodel] = useState(false);
  const [openverify, setopenverify] = useState(false);
  const [timersec, settimersec] = useState(20);

  const [counterSecond, setCounterSecond] = useState(0);
  const [counter, setCounter] = useState(120);
  const [time, setTime] = useState("");
  const [otpvalidmsg, setotpvalidmsg] = useState("");
  const [updatedbyvalid, setupdatedbyvalid] = useState("");

  const [roomdet, setroomdet] = useState([]);
  const [Updatedbyval, setupdatebyval] = useState("");
  const [otp_value, setvaluefunc] = useState();
  const [newchid, setnewchid] = useState();

  let items = [];
  let rdet = [];
  let locarr = [];
  let ownerdet = [];
  let cdet = [];
  
  useEffect(() => {
    window.scrollTo(0, 0);
    if (basic[0]?.propertyname === "" || basic[0]?.propertyname === undefined) {
      navigate("/");
    }
    let qualityamenities =
      (facilities[0]?.CleaninessAmenities ===
        facilities[0]?.CleaninessAmenities.length) ===
      0
        ? facilities[0]?.CleaninessAmenities.push("N/A")
        : facilities[0]?.CleaninessAmenities;

    const el = document.getElementById("report");

    setprname(propname);
    setgstnm(gstnum);
    data1cont = profileDetails[0]?.contactarr.filter((dt) => {
      return dt.contactType == "Sales";
    });
    // localStorage.clear();
    frontofficedata = profileDetails[0]?.contactarr.filter((fd) => {
      return fd.contactType == "Front Office";
    });
    Gmdata = profileDetails[0]?.contactarr.filter((gmdt) => {
      return gmdt.contactType == "GM";
    });
    Reservation = profileDetails[0]?.contactarr.filter((rsrvation) => {
      return rsrvation.contactType == "Reservation";
    });
    finance_manager = profileDetails[0]?.contactarr.filter((f_manager) => {
      return f_manager.contactType == "Finance Manager";
    });
    othercontactdetail = profileDetails[0]?.contactarr.filter((o_manager) => {
      return ![
        "Front Office",
        "Sales",
        "GM",
        "Reservation",
        "Finance Manager",
      ].includes(o_manager.contactType);
    });
    setGmdataarr(Gmdata);

    setsalesdata(data1cont);
    setfrontoffdata(frontofficedata);
    setreservationdata(Reservation);
    setFinancemanager(finance_manager);
    setOthersmanager(othercontactdetail);

    setcontacdet(cdet);
    setroomdet(rdet);
  }, []);

  const handleCancel = () => {
    setopenmodal(false);
  };
  const handleCancelerr = () => {
    seterrormodel(false);
  };

  const handleCancelotp = () => {
    setOpen(false);
    setopenotpverify(false);
    setVerification(0);
    setvaluefunc("");
    setupdatebyval("")
  };

  const handleCancelerror = () => {
    setopenerror(false);
  };
  const handleCancelsuccess = () => {
    window.location.replace("https://www.hummingbirdindia.com/");
    setopenmodalsuccess(false);
  };

  const { Column } = Table;
  const { TextArea } = Input;
  const description = "This is a description.";
  const options = [
    {
      value: "Burns Bay Road",
    },
    {
      value: "Downing Street",
    },
    {
      value: "Wall Street",
    },
  ];

  let allmailid = [
    frontoffdata?.length > 0 && frontoffdata,
    salesdata?.length > 0 && salesdata,
    financemanager?.length > 0 && financemanager,
    Reservationdata?.length > 0 && Reservationdata,
  ].flat(2);
  let all_mail_id = [...allmailid]
    ?.map((item) => item.Email)
    .filter((item) => item !== undefined);

  let frontoff_mobileno =
    frontoffdata?.length > 0
      ? frontoffdata[0].mobileno
      : salesdata?.length > 0
      ? salesdata[0].mobileno
      : financemanager?.length > 0
      ? financemanager[0].mobileno
      : Gmdataarr?.length > 0
      ? Gmdataarr[0].mobileno
      : Reservationdata?.length > 0
      ? Reservationdata[0].mobileno
      : othersmanager?.length > 0
      ? othersmanager[0].mobileno
      : " N/A";
  let frontoff_mail_id =
    frontoffdata?.length > 0
      ? frontoffdata[0].Email
      : salesdata?.length > 0
      ? salesdata[0].Email
      : financemanager?.length > 0
      ? financemanager[0].Email
      : Gmdataarr?.length > 0
      ? Gmdataarr[0].Email
      : Reservationdata?.length > 0
      ? Reservationdata[0].Email
      : othersmanager?.length > 0
      ? othersmanager[0].Email
      : " N/A";
  //let mail_id = [frontoffdata[0]?.Email,salesdata[0]?.Email,financemanager[0]?.Email,Gmdataarr[0]?.Email,Reservationdata[0]?.Email,Reservationdata[0]?.Email,othersmanager[0]?.Email]
  let salesmailid = salesdata?.length > 0 && salesdata[0]?.Email;
  let reservationmailid =
    Reservationdata?.length > 0 && Reservationdata[0].Email;
  let financemailid = financemanager?.length > 0 && financemanager[0].Email;
  //let mail_id = [frontoff_mail_id,salesmailid && salesmailid,reservationmailid && reservationmailid,financemailid && financemailid];
  let mail_id =
    (all_mail_id.length > 0 && all_mail_id) || othersmanager[0]?.Email;
  let propertyImages = propImages.map((item) => ({
    Id: item?.uid,
    imagepath1: item?.ImageLocation1 || item?.ImageLocation,
    imagename1: item?.imagename1 || item?.imagename,
  }));
  //let imagesprop = ( deleteimages || propImagespath?.length > 0 ? propImagespath : screenimages
  let imagesprop1 = deleteimages.map((item) => ({
    Id: item?.Id ? item?.Id : 0,
    imagepath1: item?.ImageLocation1 || item?.ImageLocation,
    imagename1: item?.imagename1 || item?.imagename,
  }));

  let images_array = [...deleteimages, ...propImagespath].flat(Infinity);

  let imagesprop = images_array?.map((item) => ({
    imagepath: item?.ImageLocation,
    imagepath1:
      item?.ImageLocation1 === undefined
        ? item?.ImageLocation
        : item?.ImageLocation1,
    imagename:
      item?.imagename === undefined ? item?.imagename1 : item?.imagename,
    imagename1: item?.imagename1,
    Id: item?.id ? item?.id : 0,
  }));

  let safetyamenities = facilities[0]?.safetyAmenities.filter(
    (item) => (item !== false || item !== undefined) && item
  );
  let QualityAmenities = facilities[0]?.CleaninessAmenities.filter(
    (item) => (item !== false || item !== undefined) && item
  );

  const functionshow = () => {
    //setIsLoading(true);
    if (termsconditions === true) {
      setopenmodal(false);

      const propimagename = propImages.map((dt) => {
        return dt.name;
      });

      let new_contact_details = profileDetails[0]?.contactarr.filter(item => item?.id === 0);
    

      let contdet = [...profileDetails[0]?.contactarrdelete,...new_contact_details].map((item) => ({
        id: item?.id,
        contactType: item?.contactType,
        contactName: item?.contactName,
        Email: item?.Email,
        mobileno: item?.mobileno,
        landlineno: item?.landlineno,
      }));

      let contactdetpdf = profileDetails[0]?.contactarr.map((item) => ({
        id: item?.id,
        contactType: item?.contactType,
        contactName: item?.contactName,
        Email: item?.Email,
        mobileno: item?.mobileno,
        landlineno: item?.landlineno,
      }));
 

      // // contact delete
      // let contactdelete = profileDetails[0]?.contactarrdelete.map(item => (
      //   {
      //     id: item?.id ? item.id : 0,
      //     contactType: item?.contactType,
      //     contactName: item?.contactName,
      //   Email: item?.Email,
      //     mobileno: item?.mobileno,
      //     landlineno: item?.landlineno

      // }));

      //delete contact details

      let deletecontactarray = profileDetails[0]?.contactarrdelete?.filter(
        (item) => item?.id !== 0
      );

      let changesdata = [...new Set(changeschecking)];

      let propid = parseInt(basic[0]?.propid);

      //for pdf
      let roomdetails_pdf = roomDetails?.roomdetarr?.map((item) => ({
        id: item?.id,
        RoomType: item?.RoomType,
        ValidityFrom: item?.ValidityFrom,
        ValidityTo: item?.ValidityTo,
        SingleRackRate: item?.SingleRackRate,
        SingleDiscountRate: item?.SingleDiscountRate,
        DoubleRackRate: item?.DoubleRackRate,
        DoubleDiscountRate: item?.DoubleDiscountRate,
        MealPlan: item?.MealPlan,
        Tacper: item?.Tacper,
        Inclusions: item?.Inclusions,
        Roomimagepath: item?.Roomimagepath,
        RoomImagename:
          item?.RoomImagename === undefined ? "" : item?.RoomImagename,
        Inclusive: item?.trafficoption,
        TACinclusive:
          item?.TACinclusive === false || item?.TACinclusive === 0 ? 0 : 1,
        Temp: item?.Temp === false || item?.Temp === 0 ? 0 : 1,
        Conference:
          item?.Conference === false || item?.Conference === 0 ? 0 : 1,
        Visible: item?.Visible === false || item?.Visible === 0 ? 0 : 1,
      }));

      let newroomtarif = roomDetails?.roomdetarr.filter(
        (item) => parseInt(item.id) === 0
      );

      let roomdetails = newroomtarif.map((item) => ({
        id: item?.id,
        RoomType: item?.RoomType,
        ValidityFrom: item?.ValidityFrom,
        ValidityTo: item?.ValidityTo,
        SingleRackRate: item?.SingleRackRate,
        SingleDiscountRate: item?.SingleDiscountRate,
        DoubleRackRate: item?.DoubleRackRate,
        DoubleDiscountRate: item?.DoubleDiscountRate,
        MealPlan: item?.MealPlan,
        Tacper: item?.Tacper,
        Inclusions: item?.Inclusions,
        Roomimagepath: item?.Roomimagepath,
        RoomImagename:
          item?.RoomImagename === undefined ? "" : item?.RoomImagename,
        Inclusive: item?.trafficoption,
        TACinclusive:
          item?.TACinclusive === false || item?.TACinclusive === 0 ? 0 : 1,
        Temp: item?.Temp === false || item?.Temp === 0 ? 0 : 1,
        Conference:
          item?.Conference === false || item?.Conference === 0 ? 0 : 1,
        Visible: item?.Visible === false || item?.Visible === 0 ? 0 : 1,
      }));

     

      const propertydata = {
        PropertyName: basic[0]?.propertyname,
        Propertydescription: basic[0]?.propdescription,
        Addrsline1: locationValue[0]?.Addrsline1,
        Addrsline2: locationValue[0]?.Addrsline2,
        State: locationValue[0]?.Statename,
        City: locationValue[0]?.Cityname,
        Locality: locationValue[0]?.localityname,
        StateId: locationValue[0]?.StateId,
        CityId: locationValue[0]?.CityId,
        localityId: locationValue[0]?.localityId || 0,
        Pincode: locationValue[0]?.Pincode,
        Directions: locationValue[0]?.Directions,
        latlng:
          locationValue[0]?.latlng.lat + "," + locationValue[0]?.latlng.lng,
        localityname: locationValue[0]?.localityname,
        SwimmingPoolServices: facilities[0]?.Other_info_check_result[4],
        GymServices: facilities[0]?.Other_info_check_result[5],
        ConferenceHall: facilities[0]?.Other_info_check_result[2],
        RestaurtantServices: facilities[0]?.Other_info_check_result[3],
        LaundryServices: facilities[0]?.Other_info_check_result[0],
        CyberCafeWifi: facilities[0]?.Other_info_check_result[1],
        propertytype:
          parseInt(basic[0]?.propertytype) < 6
            ? parseInt(basic[0]?.propertytype) + 1
            : parseInt(basic[0]?.propertytype),
        cancellationpolicy: basic[0]?.cancellationpolicy,
        CheckinTime:
          basic[0]?.checkintime === " " ? "24" : basic[0]?.checkintime,
        CheckinType:
          basic[0]?.checkintypeampm === " " ? "Hrs" : basic[0]?.checkintypeampm,
        CheckOutTime:
          basic[0]?.checkouttime === " " ? "24" : basic[0]?.checkouttime,
        CheckOutType:
          basic[0]?.checkouttypeampm === " "
            ? "Hrs"
            : basic[0]?.checkouttypeampm,
        Gstnumber: basic[0]?.gstnumber,
        legalname: basic[0]?.legalname,
        gststatus: basic[0]?.gststatus,
        btccreditperiod:
          roomDetails?.btccreditperiod > 0 ? roomDetails?.btccreditperiod : 0,
          btc:roomDetails?.btccreditperiod > 0 ? 1 : 0,
        Channelmanager: basic[0]?.channelmanageroption === "Yes" ? 
        (basic[0]?.otherstext !== "" && basic[0]?.otherstext !== undefined) ? 
        basic[0]?.otherstext : basic[0]?.Channelmanager : "",
        chnmanagerid:
          basic[0]?.channelmanageroption === "Yes" ?
            (newchid !== '' && newchid !== undefined) ? newchid : basic[0]?.chnlmanagerid :0,
        TourAssistance: facilities[0]?.Other_info_check_result[6],
        PetFriendly: facilities[0]?.Other_info_check_result[7],
        Parking: facilities[0]?.Other_info_check_result[8],
        WomenSafety: facilities[0]?.safety_check_result[0],
        Freefacemasks: facilities[0]?.Cleanliness_check_result[1],
        Contactlesscheckin: facilities[0]?.safety_check_result[2],
        CleanedbyprofessionalcleaningTeam:
          facilities[0]?.Cleanliness_check_result[3],
        Handsanitizer: facilities[0]?.Cleanliness_check_result[4],
        Freeofpests: facilities[0]?.Cleanliness_check_result[2],
        Hygienictowelsandblanket: facilities[0]?.Cleanliness_check_result[0],
        WaterandElectricity: facilities[0]?.Cleanliness_check_result[5],
        CCTVsurveillance: facilities[0]?.safety_check_result[1],
        Securityguard: facilities[0]?.safety_check_result[3],
        Fireextinguisher: facilities[0]?.safety_check_result[4],
        Emergencyexit: facilities[0]?.safety_check_result[5],
        cancalpolicy1: basic[0]?.Policies1 || basic[0]?.cancelpolicy1,
        cancalpolicy2: basic[0]?.Policies2 || basic[0]?.cancalpolicy2,
        AccountType: profileDetails[0].Propertyowners[0]?.AccountType,
        Accountnumber: profileDetails[0].Propertyowners[0]?.Accountnumber,
        Bankname: profileDetails[0].Propertyowners[0]?.Bankname,
        IFSCcode: profileDetails[0].Propertyowners[0]?.IFSCcode,
        Payeename: profileDetails[0].Propertyowners[0]?.Payeename,
        UpiId: profileDetails[0].Propertyowners[0]?.UpiId,
        Upimobilenumber:
          profileDetails[0].Propertyowners[0]?.UpiId !== ""
            ? profileDetails[0].Propertyowners[0]?.Upimobilenumber
            : "",
        UPIName:
          profileDetails[0].Propertyowners[0]?.UpiId !== ""
            ? profileDetails[0].Propertyowners[0]?.Upiname === undefined
              ? ""
              : profileDetails[0].Propertyowners[0]?.Upiname
            : "",
        branchAddress: profileDetails[0].Propertyowners[0]?.branchAddress || "",
        checkleafpath:
          profileImagePath[0] ||
          profileDetails[0].Propertyowners[0]?.chkleafimagepath[0] ||
          profileDetails[0].Propertyowners[0]?.checkleafpath[0]?.ImageLocation,
        roomdet: roomdetails,
        roomdetails_pdf: roomdetails_pdf,
        contactdet: contdet,
        contactdetpdf:contactdetpdf,
        PropertyId: basic[0]?.propid,
        // imagedet: imagesprop.length > 0 ? imagesprop : propertyImages,
        imagedet: imagesprop.length > 0 ? imagesprop : [],
        UserId: 0,
        // LegalAddress: basic[0]?.LegalAddress,
        LegalAddress: locationValue[0]?.HBAddress.replace("Regd Office :", ""),
        mail_id: mail_id,

        safetyamenities: safetyamenities,
        QualityAmenities: QualityAmenities,
        HBAddress: locationValue[0]?.HBAddress.replace("Regd Office :", ""),
        tradename: basic[0]?.tradename === null ? "" : basic[0]?.tradename,
        imagename: propimagename,
        website: basic[0]?.websiteAddress,

        dataChange: changesdata,
        propid_aggrid: property_aggreid,
        oldupiid: oldupiid && oldupiid,
        gst_payeeflag:profileDetails[0]?.gst_payeename_verifi == true ? 1 : 0,
      };
      setupdateproperty(propertydata);
      let otpcode = Math.floor(100000 + Math.random() * 900000);
      setVerification(otpcode);

      let otpid = Math.floor(100000000 + Math.random() * 900000000);
      setVerificationid(otpid);
      let verifi_data = {
        propertyname: basic[0]?.propertyname,
        otp: otpcode,
        dataChange: [...new Set(changeschecking)],
        id: otpid,
        mobileNo: frontoff_mobileno,
        mailid: frontoff_mail_id,
      };
console.log(verifi_data)

      if (changeschecking.length > 0) {

         // email otp 
axios
.post(`${property_update}/emailverification`, verifi_data, {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
  timeout: 10000,
})
.then((res) => {console.log(res)})
.catch(
  (error) => {console.log(error)} 
  
);


        axios
          .post(`${property_update}/otpverification`, verifi_data, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
            timeout: 10000,
          })
          .then((res) => {console.log(res)})
          .catch(
            (error) => {console.log(error)} 
            
          );

         

        const otpdata = {
          rowid: `${idforUpdation}`,
          otpnum: `${otpcode}`,
        };
        // var date = new Date();
        // var seconds = date.getTime() / 1000; //1440516958
        //clear the time
        setTimeout(() => {
          setVerification(0);
        }, 300 * 1000);

        //setcurrenttime(seconds);

        setopenotpverify(true);

        axios
          .post(`${property_update}/otpinserttodb`, otpdata, {
             headers: {
               "Access-Control-Allow-Origin": "*",
               "Content-Type": "application/json",
               "Access-Control-Allow-Credentials": "true"
             },
          })
          .then((res) => {})
          .catch((error) => {});
      } else {
        setmessagearr("No data have changed");
        setOpen(true);
      }
    } else {
      setopenmodal(true);
    }
  };

  //var date = new Date("2015-08-25T15:35:58.000Z");
  //var seconds = date.getTime() / 1000; //1440516958

  useEffect(() => {
    //   let timer2;
    //  if(parseInt(verification) !== 0)
    // {timer2 = setTimeout(() => {setVerification(0);
    //   setopenotpverify(false);
    // }, 1* 20 * 1000)}
    // else
    //   {clearTimeout(timer2)}
  
    
    if(basic[0]?.otherstext !== "" && basic[0]?.otherstext !== undefined) {
    const data = {
      ChannelManager: basic[0]?.otherstext,
    };
    axios
                        .post(
                          `${onboardapi}/Insertchannelmanager`,
                          data
                        )
                        .then((res) => {
                          //setchnlmanagerïd(res.data[0].Id);
                          setnewchid(res.data[0].Id);
                        })
                        .catch((err) => {
                          
                        });
                      }
  }, []);
  // useEffect(() => {
  //   setTimeout(() => setCounterSecond(counterSecond + 1), 1000);
  //   setTimeout(() => setCounter(counter - 1), 1000);
  // }, [counterSecond , counter]);
  const handletimer = () => {
    setTime(counterSecond);

    setOpen(false);
    setopenotpverify(false);
    setVerification(0);
  };

  const verifyotpfunc = () => {
    let otpfield = document.querySelector(".otpfield");


    if (
      otpfield?.value !== "" && Updatedbyval !== "" ) {
      setIsLoading(true);
      if (parseInt(otpfield?.value) === parseInt(verification)) {
        setotpvalidmsg("Valid");
        setopenotpverify(false);
        setotpvalidmsg("");
        
        setVerification(0);

        let addupdatedby = { ...updateproperty, Updatedby: Updatedbyval };

        const propresponse = axios

          .post(`${property_update}/PropertyUpdate`, addupdatedby, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          })
          .then(async (res) => {
            if (res?.data[0]?.Msg === "Property Updated Successfully") {
              setopenmodal(false);
              setopenerror(false);
              setopenmodalsuccess(true);

              setIsLoading(false);
              setnewchid()
            }

            setopenmodalsuccess(true);
            setIsLoading(false);
            setsucess(true);

            //easy buzz start

           
            if (res?.data?.data[0]?.PropertyOwnersId !== "") {
              let easybuzzbody = {
                PropertyName: basic[0]?.propertyname,
                UPIName:
                  profileDetails[0].Propertyowners[0]?.UpiId !== ""
                    ? profileDetails[0].Propertyowners[0]?.Upiname === undefined
                      ? ""
                      : profileDetails[0].Propertyowners[0]?.Upiname
                    : "",
                UpiId: profileDetails[0].Propertyowners[0]?.UpiId,
                oldupiid: oldupiid,
                ownerid: await res?.data?.data[0]?.PropertyOwnersId,
                userId: 2029,
              };

              axios
                .post(`${property_update}/easybuzz`, easybuzzbody, {
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                  },
                })
                .then((res) => {
                 
                })
                .catch((error) => {
                
                });
            }
            otpfield.value = "";
            //easy buzz end
            setvaluefunc("");
          })

          .catch((err) => {
            setopenmodal(false);
            setIsLoading(false);
            
            seterrormodel(true);
            // setopenerror(true)
          });
      } else {
        setotpvalidmsg("Please enter valid OTP");
        setIsLoading(false);
      }
    } else {
      if (otpfield?.value === "") {
        setotpvalidmsg("Please enter OTP");
      }

      if (Updatedbyval === "") {
        setupdatedbyvalid("Please enter updated by");
      }
    }

    
  };

  const datasourcearray = [
    {
      key: "1",
      Particulars: <b>GSTIN</b>,
      numbername: "",
      singletariff:
        (gstvarified_details && gstvarified_details?.gstin) ||
        basic[0]?.gstnumber,
    },
    {
      key: "2",
      Particulars: <b>Taxpayer Legal Name</b>,
      numbername: "",
      singletariff:
        (gstvarified_details && gstvarified_details?.lgnm) ||
        basic[0]?.legalname ||
        "N/A",
    },
    {
      key: "3",
      Particulars: <b>Taxpayer Trade Name</b>,
      numbername: "",
      singletariff:
        (gstvarified_details && gstvarified_details?.tradeNam) ||
        basic[0]?.tradename ||
        "N/A",
    },
  ];
  const datasource = datasourcearray.filter(
    (item) => item.singletariff !== null && item
  );

  const onChangechecktime = (e) => {
    if (e.target.checked === true) {
      setTermsconditions(true);
    } else {
      setTermsconditions(false);
    }
  };
  const closeverify = () => {
    setopenverify(false);
  };
  //  const timerfunc = (val) => {
  //   for(let i = 0; i < 20; i++) {
  //     setTimeout(() => {
  //       settimersec(pre => pre - 1)
  //     }, i * 1000);
  //   }

  //  }

  return (
    <div>
      {isLoading && (
        <div className="loaderloading">
          <div className="example">
            <Spin />{" "}
            <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
              Loading ...
            </span>
          </div>
        </div>
      )}
      {openverify && (
        <div className="otpverificationscreen">
          <div className="otppopup">popup</div>
          <button onClick={closeverify}>click</button>
        </div>
      )}
      <nav className="row">
        <a className="navbar-brand col-md-2 text-center mobile-show" href="#">
          <img
            className=""
            src={logo_img}
            alt=""
            width={"120px"}
            height={"85px"}
          />
        </a>

        <span className="text-center col-md-12 d-flex justify-content-center align-items-center">
          <h3>
            <b>Property Onboarding</b>
          </h3>
        </span>
      </nav>
      <div className="container-fluid publish_blk">
        <div className="row">
          <div
            className="col-md-2 no-border-l h100"
            style={{ background: "#fff", borderLeft: "none" }}
          >
            <Card className=" col-md-12 h-100 side-menu">
              <Steps
                size="small"
                className="mt-md-5 cursor-not-allowed mobile-hide"
                direction="vertical"
                current={6}
              >
                <Steps.Step title={<NavLink to="/">Basics</NavLink>} />
                <Steps.Step
                  title={<NavLink to="/location">Location</NavLink>}
                />
                <Steps.Step
                  title={<NavLink to="/facilities">Facilities</NavLink>}
                />
                <Steps.Step
                  title={<NavLink to="/roomdet">Room details</NavLink>}
                />
                <Steps.Step title={<NavLink to="/photos">Photos</NavLink>} />
                <Steps.Step title={<NavLink to="/Profile">Contact</NavLink>} />
                <Steps.Step title={<NavLink to="/Publish">Publish</NavLink>} />
              </Steps>
              <a
                className="navbar-brand col-md-2 text-center logo-display"
                href="#"
              >
                <img className="logo_name" src={logo_img} alt="" width="100%" />
              </a>
              <p className="mt-sm-3 desk_browser" style={{ fontSize: "12px" }}>
                <span
                  className="fw-bold"
                  style={{ fontSize: "14px", marginBottom: "10px" }}
                >
                  Supported Browsers :{" "}
                </span>
                <br />{" "}
                <span style={{ margin: "0px", lineHeight: "10px" }}>
                  
                </span>
                1. Google Chrome,
                <br />
                2. Microsoft Edge,
                <br />
                3. Firefox.
              </p>
            </Card>
            {/* <div className='d-flex justify-content-center'> */}
          </div>
          <div
            className="col-md-10 layout-ml"
            style={{ background: "rgb(227 245 255)" }}
          >
            <div className="row ">
              <div className="col-sm-6 p-3">
                <h3>Sign your contract and let’s get booking.</h3>
                <p>Just one more step to complete.</p>
              </div>
              <div className="col-sm-6 mt-2">
                <img src={roomheadpic} alt="" height={"120px"} />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-9" id="report" ref={ref1}>
                <h5>
                  MOU / Service Agreement for Business STAY Management Services.
                </h5>

                <Card
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                  }}
                >
                  <div className="row">
                    <div className="col-sm-12 publish p-0">
                      <div>
                        <Descriptions bordered layout={"vertical"}>
                          <Descriptions.Item
                            label={
                              <span style={{ fontWeight: "bold" }}>
                                Company Name:
                              </span>
                            }
                            style={{ verticalAlign: "top", width: "33%" }}
                          >
                            <b>HummingBird Digital Pvt Ltd,</b>
                            <br />
                            <span
                              style={{
                                width: "74px",
                                display: "inline-block",
                                fontWeight: "bold",
                              }}
                            >
                              GSTIN{" "}
                            </span>
                            &nbsp; :&nbsp;
                            {locationValue[0]?.stategstnum ||
                              " 29AABCH5874R1ZN"}
                            <br />
                            <span
                              style={{
                                width: "74px",
                                display: "inline-block",
                                float: "left",
                              }}
                            >
                              {" "}
                              Regd Office
                            </span>
                            &nbsp; :{" "}
                            <span>
                              {locationValue[0]?.HBAddress.replace(
                                "Regd Office :",
                                ""
                              ) ||
                                " No.8/4, 3rd Floor, Gurumarthappa Garden, 6th Cross, Sarakki Main Road, 1st Phase, J.P. Nagar, Bangalore 560078."}{" "}
                            </span>
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={
                              <span style={{ fontWeight: "bold" }}>
                                Property Name and Address:
                              </span>
                            }
                            style={{ verticalAlign: "top", width: "33%" }}
                          >
                            <li>
                              <b>{basic[0]?.propertyname},</b>
                            </li>
                            <li>
                              {locationValue[0]?.Addrsline1 +
                                ", " +
                                locationValue[0]?.Addrsline2 +
                                ", " +
                                locationValue[0]?.localityname +
                                ", " +
                                locationValue[0]?.Cityname +
                                ", " +
                                locationValue[0]?.Statename +
                                " - " +
                                locationValue[0]?.Pincode}
                            </li>
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={
                              <span style={{ fontWeight: "bold" }}>
                                Service Provided:
                              </span>
                            }
                            style={{ verticalAlign: "top", width: "33%" }}
                          >
                            <ol type="1" style={{ paddingLeft: "10px" }}>
                              <li>
                                Provide rooms for transit stay for corporate
                                clients & retail
                              </li>
                              <li>Provide rooms for long stay</li>
                              <li>Provide meals & Wifi to the traveler</li>
                              <li>
                                Provide conference room facility if available
                              </li>
                              <li>Provide car park if available</li>
                            </ol>
                          </Descriptions.Item>
                          {facilities[0]?.CleaninessAmenities !== undefined && (
                            <Descriptions.Item
                              label={
                                <span style={{ fontWeight: "bold" }}>
                                  Quality Requirements:
                                </span>
                              }
                              style={{ verticalAlign: "top" }}
                            >
                              <ol type="1" style={{ paddingLeft: "10px" }}>
                                {facilities[0]?.CleaninessAmenities !==
                                  undefined &&
                                  facilities[0]?.CleaninessAmenities?.map(
                                    (item, index) =>
                                      item && <li key={index}>{item}</li>
                                  )}
                              </ol>
                            </Descriptions.Item>
                          )}
                          {facilities[0]?.safetyAmenities !== undefined && (
                            <Descriptions.Item
                              label={
                                <span style={{ fontWeight: "bold" }}>
                                  Safety Requirements:
                                </span>
                              }
                              style={{ verticalAlign: "top" }}
                            >
                              <ol style={{ paddingLeft: "10px" }}>
                                {facilities[0]?.safetyAmenities !== undefined &&
                                  facilities[0]?.safetyAmenities.map(
                                    (item, index) =>
                                      item && <li key={index}>{item}</li>
                                  )}
                              </ol>
                            </Descriptions.Item>
                          )}

                          <Descriptions.Item
                            label={
                              <span style={{ fontWeight: "bold" }}>
                                Payment Terms:
                              </span>
                            }
                            style={{ verticalAlign: "top" }}
                          >
                            <ol type="1" style={{ paddingLeft: "10px" }}>
                              <li>
                                If the traveler pay at the property. The
                                business fee agreed on will be transferred to HB
                              </li>

                              <li>
                              If HB collect the payment, HB will process the payment to property as per credit period
                              </li>
                            </ol>
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={
                              <span style={{ fontWeight: "bold" }}>
                                HB Business Fee:
                              </span>
                            }
                            style={{ verticalAlign: "top" }}
                          >
                            Refer Annexure A
                          </Descriptions.Item>

                          <Descriptions.Item
                            label={
                              <span style={{ fontWeight: "bold" }}>
                                Front Office:
                              </span>
                            }
                            style={{ verticalAlign: "top" }}
                          >
                            <span
                              style={{ width: "67px", display: "inline-block" }}
                            >
                              Name{" "}
                            </span>{" "}
                            :{" "}
                            {frontoffdata?.length > 0
                              ? frontoffdata[0].contactName
                              : salesdata?.length > 0
                              ? salesdata[0].contactName
                              : financemanager?.length > 0
                              ? financemanager[0].contactName
                              : Gmdataarr?.length > 0
                              ? Gmdataarr[0].contactName
                              : Reservationdata?.length > 0
                              ? Reservationdata[0].contactName
                              : othersmanager?.length > 0
                              ? othersmanager[0].contactName
                              : " N/A"}
                            <br />
                            <span
                              style={{ width: "67px", display: "inline-block" }}
                            >
                              Mobile No{" "}
                            </span>{" "}
                            : {frontoff_mobileno}
                            <br />
                            <span
                              style={{ width: "67px", display: "inline-block" }}
                            >
                              Email{" "}
                            </span>{" "}
                            : {frontoff_mail_id}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={
                              <span style={{ fontWeight: "bold" }}>Sales:</span>
                            }
                            style={{ verticalAlign: "top" }}
                          >
                            <span
                              style={{ width: "67px", display: "inline-block" }}
                            >
                              Name{" "}
                            </span>
                            :{" "}
                            {salesdata?.length > 0
                              ? salesdata[0].contactName
                              : " N/A"}
                            <br />
                            <span
                              style={{ width: "67px", display: "inline-block" }}
                            >
                              Mobile No{" "}
                            </span>
                            :{" "}
                            {salesdata?.length > 0
                              ? salesdata[0].mobileno
                              : " N/A"}
                            <br />
                            <span
                              style={{ width: "67px", display: "inline-block" }}
                            >
                              Email{" "}
                            </span>
                            :{" "}
                            {salesdata?.length > 0
                              ? salesdata[0].Email
                              : " N/A"}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={
                              <span style={{ fontWeight: "bold" }}>GM:</span>
                            }
                            style={{ verticalAlign: "top" }}
                          >
                            <span
                              style={{ width: "67px", display: "inline-block" }}
                            >
                              Name{" "}
                            </span>{" "}
                            :{" "}
                            {Gmdataarr?.length > 0
                              ? Gmdataarr[0].contactName
                              : " N/A"}
                            <br />
                            <span
                              style={{ width: "67px", display: "inline-block" }}
                            >
                              Mobile No{" "}
                            </span>{" "}
                            :{" "}
                            {Gmdataarr?.length > 0
                              ? Gmdataarr[0].mobileno
                              : " N/A"}
                            <br />
                            <span
                              style={{ width: "67px", display: "inline-block" }}
                            >
                              Email{" "}
                            </span>{" "}
                            :{" "}
                            {Gmdataarr?.length > 0
                              ? Gmdataarr[0].Email
                              : " N/A"}
                          </Descriptions.Item>

                          <Descriptions.Item
                            label={
                              <span style={{ fontWeight: "bold" }}>
                                Reservation:
                              </span>
                            }
                            style={{ verticalAlign: "top" }}
                          >
                            <span
                              style={{ width: "67px", display: "inline-block" }}
                            >
                              Name{" "}
                            </span>{" "}
                            :{" "}
                            {Reservationdata?.length > 0
                              ? Reservationdata[0].contactName
                              : " N/A"}{" "}
                            <br />
                            <span
                              style={{ width: "67px", display: "inline-block" }}
                            >
                              Mobile No{" "}
                            </span>{" "}
                            :{" "}
                            {Reservationdata?.length > 0
                              ? Reservationdata[0].mobileno
                              : " N/A"}
                            <br />
                            <span
                              style={{ width: "67px", display: "inline-block" }}
                            >
                              Email{" "}
                            </span>{" "}
                            :{" "}
                            {Reservationdata?.length > 0
                              ? Reservationdata[0].Email
                              : " N/A"}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={
                              <span style={{ fontWeight: "bold" }}>
                                Finance Manager:
                              </span>
                            }
                            style={{ verticalAlign: "top" }}
                          >
                            <span
                              style={{ width: "67px", display: "inline-block" }}
                            >
                              Name{" "}
                            </span>{" "}
                            :{" "}
                            {financemanager?.length > 0
                              ? financemanager[0].contactName
                              : " N/A"}
                            <br />
                            <span
                              style={{ width: "67px", display: "inline-block" }}
                            >
                              Mobile No{" "}
                            </span>{" "}
                            :{" "}
                            {financemanager?.length > 0
                              ? financemanager[0].mobileno
                              : " N/A"}
                            <br />
                            <span
                              style={{ width: "67px", display: "inline-block" }}
                            >
                              Email{" "}
                            </span>{" "}
                            :{" "}
                            {financemanager?.length > 0
                              ? financemanager[0].Email
                              : " N/A"}
                            <br />
                          </Descriptions.Item>

                          <Descriptions.Item
                            label={
                              <span style={{ fontWeight: "bold" }}>
                                Bank Details:
                              </span>
                            }
                            style={{ verticalAlign: "top" }}
                          >
                            <table border="0">
                              <tbody>
                                <tr>
                                  <td
                                    style={{
                                      verticalAlign: "top",
                                      width: "105px",
                                    }}
                                  >
                                    Account No{" "}
                                  </td>
                                  <td style={{ verticalAlign: "top" }}>
                                    {" "}
                                    :{" "}
                                    {
                                      profileDetails[0]?.Propertyowners[0]
                                        ?.Accountnumber
                                    }
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    style={{
                                      verticalAlign: "top",
                                      width: "105px",
                                    }}
                                  >
                                    IFSC Code
                                  </td>
                                  <td style={{ verticalAlign: "top" }}>
                                    {" "}
                                    :{" "}
                                    {
                                      profileDetails[0]?.Propertyowners[0]
                                        ?.IFSCcode
                                    }
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    style={{
                                      verticalAlign: "top",
                                      width: "105px",
                                    }}
                                  >
                                    Payee Name
                                  </td>
                                  <td style={{ verticalAlign: "top" }}>
                                    {" "}
                                    :{" "}
                                    {
                                      profileDetails[0]?.Propertyowners[0]
                                        ?.Payeename
                                    }
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    style={{
                                      verticalAlign: "top",
                                      width: "105px",
                                    }}
                                  >
                                    Bank Name
                                  </td>
                                  <td style={{ verticalAlign: "top" }}>
                                    {" "}
                                    :{" "}
                                    {
                                      profileDetails[0]?.Propertyowners[0]
                                        ?.Bankname
                                    }
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    style={{
                                      verticalAlign: "top",
                                      width: "105px",
                                    }}
                                  >
                                    Account Type
                                  </td>
                                  <td style={{ verticalAlign: "top" }}>
                                    {" "}
                                    :{" "}
                                    {
                                      profileDetails[0]?.Propertyowners[0]
                                        ?.AccountType
                                    }
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    style={{
                                      verticalAlign: "top",
                                      width: "105px",
                                    }}
                                  >
                                    Branch Address
                                  </td>
                                  <td style={{ verticalAlign: "top" }}>
                                    {" "}
                                    :{" "}
                                    {profileDetails[0]?.Propertyowners[0]
                                      ?.branchAddress || "N/A"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Descriptions.Item>
                        </Descriptions>
                        <hr />
                      </div>
                      <p className="mt-md-4 ms-sm-2 mt-sm-2">
                        This is in principle acceptance of terms and conditions.
                        <br />
                        We may enter into a formal service agreement on mutual
                        agreed terms & conditions.
                      </p>
                      <p className="ms-sm-2"> </p>

                      <div className="row">
                        <div className="col-sm-7">
                          <p className="ms-sm-2">
                            For <b>HummingBird Digital Pvt Ltd</b>
                          </p>
                        </div>
                        <div className="col-sm-5">
                          <p className=" float-end d-inline-block">
                            for, <b>{basic[0]?.legalname}</b>
                          </p>
                        </div>
                        <div className="col-sm-2"></div>
                      </div>
                      <h3
                        className="text-center"
                        style={{ fontSize: "15px", marginTop: "50px" }}
                      >
                        ANNEXURE A
                      </h3>

                      <Descriptions bordered layout={"vertical"}>
                        <Descriptions.Item
                          label={
                            <span style={{ fontWeight: "bold" }}>
                              Property&nbsp;Name:
                            </span>
                          }
                          style={{ width: "33%" }}
                        >
                          {/* {prname} */}
                          {basic[0]?.propertyname}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={
                            <span style={{ fontWeight: "bold" }}>
                              Property Address:
                            </span>
                          }
                          style={{ width: "33%" }}
                        >
                          {locationValue[0]?.Addrsline1 +
                            ", " +
                            locationValue[0]?.Addrsline2 +
                            ", " +
                            locationValue[0]?.localityname +
                            ", " +
                            locationValue[0]?.Cityname +
                            ", " +
                            locationValue[0]?.Statename +
                            " - " +
                            locationValue[0]?.Pincode}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={
                            <span style={{ fontWeight: "bold" }}>
                              Property Contact Details : Front Office
                            </span>
                          }
                          style={{ verticalAlign: "top", width: "33%" }}
                        >
                          <div className="row">
                            <div className="col-sm-12">
                              <span
                                style={{
                                  width: "67px",
                                  display: "inline-block",
                                }}
                              >
                                {" "}
                                Mobile No
                              </span>{" "}
                              : {frontoff_mobileno}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12">
                              <span
                                style={{
                                  width: "67px",
                                  display: "inline-block",
                                }}
                              >
                                Email{" "}
                              </span>{" "}
                              : {frontoff_mail_id}
                            </div>
                          </div>
                        </Descriptions.Item>
                      </Descriptions>
                      <Descriptions className="publish_full-width">
                        <Descriptions.Item>
                          <div className="row">
                            <div
                              className="col-12 col-sm-12 col-lg-6 col-md-6 publish_column"
                              style={{ float: "left" }}
                            >
                              <Descriptions bordered layout={"vertical"}>
                                <Descriptions.Item
                                  label={
                                    <span style={{ fontWeight: "bold" }}>
                                      GSTIN :
                                    </span>
                                  }
                                >
                                  <Table
                                    dataSource={datasource}
                                    className="gsttan publish_tab"
                                    pagination={false}
                                    showHeader={false}
                                  >
                                    <Column
                                      title="Particulars"
                                      key="Particulars"
                                      render={(_, record) => (
                                        <Space size="middle">
                                          <p>{record.Particulars}</p>
                                          {/* <p>{gstvarified_details[0]?.MESSAGE.result.gstin}</p> */}
                                        </Space>
                                      )}
                                    />
                                    <Column
                                      title="Number / Name"
                                      key="numbername"
                                      render={(_, record) => (
                                        <Space size="middle">
                                          <p>: {record.singletariff}</p>
                                        </Space>
                                      )}
                                    />
                                  </Table>
                                </Descriptions.Item>
                              </Descriptions>
                            </div>

                            <div
                              className="col-12 col-sm-12 col-md-6 col-lg-6 publish_column"
                              style={{ float: "left" }}
                            >
                              <Descriptions bordered layout={"vertical"}>
                                <Descriptions.Item
                                  label={
                                    <span style={{ fontWeight: "bold" }}>
                                      Check-In & Check-Out Time:
                                    </span>
                                  }
                                >
                                  <div>
                                    {" "}
                                    {/* {basic[0]?.checkintime == 24 ? <div style={{paddingLeft:"10px"}}> Check-In & Check-Out Time : <b>24 hrs  </b></div> :  */}
                                    <p style={{ paddingLeft: "10px" }}>
                                      <b>Check-In &nbsp;&nbsp;&nbsp;: </b>
                                      {basic[0]?.checkintime +
                                        " " +
                                        basic[0]?.checkintypeampm}
                                    </p>
                                    <p style={{ paddingLeft: "10px" }}>
                                      <b>Check-Out : </b>
                                      {basic[0]?.checkouttime +
                                        " " +
                                        basic[0]?.checkouttypeampm}
                                    </p>
                                    <hr />
                                    <p
                                      style={{
                                        paddingLeft: "10px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <b>BTC Credit Period &nbsp;&nbsp;: </b>
                                      {roomDetails?.btccreditperiodoption ===
                                      "Yes"
                                        ? roomDetails?.btccreditperiod
                                        : 0}{" "}
                                      days from invoice submission
                                    </p>
                                  </div>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    Cancellation Policy :{" "}
                                  </span>
                                  <p style={{ marginLeft: "10px" }}>
                                    {basic[0]?.cancellationpolicy}
                                  </p>
                                </Descriptions.Item>
                              </Descriptions>
                            </div>
                          </div>
                        </Descriptions.Item>
                      </Descriptions>

                      <Descriptions className="Tariff">
                        <div className="Tariff_block">
                          <Descriptions.Item>
                            <Table
                              dataSource={roomDetails?.roomdetarr}
                              pagination={false}
                              className="publish_tab publish_table"
                            >
                              <Column
                                title="Room Type"
                                key="rroomtype"
                                style={{ minWidth: "100px" }}
                                align="center"
                                render={(_, record) => (
                                  <Space size="middle">
                                    <p>{record.RoomType}</p>
                                  </Space>
                                )}
                              />
                              <ColumnGroup title="Rack Tariff (INR)">
                                <Column
                                  title="Single"
                                  key="rSingle"
                                  align="center"
                                  render={(_, record) => (
                                    <Space size="middle">
                                      <span
                                        style={{
                                          width: "60px",
                                          textAlign: "right",
                                          display: "block",
                                        }}
                                      >
                                        {record.SingleRackRate}
                                      </span>
                                    </Space>
                                  )}
                                  style={{ minWidth: "100px" }}
                                />

                                <Column
                                  title="Double"
                                  key="rDouble"
                                  align="center"
                                  render={(_, record) => (
                                    <Space size="middle">
                                      <span
                                        style={{
                                          width: "60px",
                                          textAlign: "right",
                                          display: "block",
                                        }}
                                      >
                                        {record.DoubleRackRate}
                                      </span>
                                    </Space>
                                  )}
                                />
                              </ColumnGroup>

                              <ColumnGroup title="Discount Tariff (INR)">
                                <Column
                                  title="Single"
                                  key="rDiscount"
                                  align="center"
                                  render={(_, record) => (
                                    <Space size="middle">
                                      <span
                                        style={{
                                          width: "60px",
                                          textAlign: "right",
                                          display: "block",
                                        }}
                                      >
                                        {record.SingleDiscountRate}
                                      </span>
                                    </Space>
                                  )}
                                />

                                <Column
                                  title="Double"
                                  key="rDDiscount"
                                  align="center"
                                  render={(_, record) => (
                                    <Space size="middle">
                                      <span
                                        style={{
                                          width: "60px",
                                          textAlign: "right",
                                          display: "block",
                                        }}
                                      >
                                        {record.DoubleDiscountRate}
                                      </span>
                                    </Space>
                                  )}
                                />
                              </ColumnGroup>
                              <Column
                                title="Tariff Include Taxes"
                                key="rtaxes"
                                align="center"
                                render={(_, record) => (
                                  <Space size="middle">
                                    {record.trafficoption}
                                  </Space>
                                )}
                                className=""
                              />
                              <Column
                                title="Inclusions"
                                key="rInclusions"
                                align="center"
                                render={(_, record) => (
                                  <Space size="middle">
                                    {record.Inclusions.map((item, index) => (
                                      <p key={index}>{item}</p>
                                    ))}
                                  </Space>
                                )}
                                className="inclusions_block"
                              />
                              <Column
                                title="TAC %"
                                key="rTAC"
                                align="center"
                                render={(_, record) => (
                                  <Space size="middle">
                                    <span
                                      style={{
                                        width: "40px",
                                        textAlign: "right",
                                        display: "block",
                                      }}
                                    >
                                      {record.Tacper}
                                    </span>
                                  </Space>
                                )}
                              />
                            </Table>
                          </Descriptions.Item>
                        </div>
                      </Descriptions>
                      <Descriptions className="kindly_note">
                        <Descriptions.Item>
                          <p>
                            <b>Kindly Note : </b>
                          </p>
                          <ul>
                            <li>
                              Capture GSTIN as per the HB booking requests,
                              property should rectify the GSTIN in-case of any
                              error found.
                            </li>
                            <li>
                              Any loss of ITC due to delay filing of GSTIN by
                              the property, needs to be reimbursed to HB.
                            </li>
                            <li>
                              GSTIN will be applicable on TAC (Travel Agent
                              Commission).
                            </li>
                            <li>
                              No-Show / cancellation retention not to be
                              charged. If the No-Show / cancellations are high,
                              it should be mutually discussed to handle future
                              bookings.
                            </li>
                            <li>
                              If HB has mark-up (Up-Sell) the agreed tariff and
                              charged the client, which must be collected at the
                              property.
                            </li>
                            <li>
                              The mark-up amount (commission) collected by the
                              property must be paid to HB against HB TAC
                              Invoice.
                            </li>
                            <li>
                              TAC / Mark-up collected to be paid by the property
                              in 10 days from the date of invoice.
                            </li>
                            <li>
                              In-case of short stay / early check-out, property
                              have to refund / Adjust advances paid (if any)
                              with the future bookings.
                            </li>
                          </ul>
                        </Descriptions.Item>
                      </Descriptions>
                    </div>
                  </div>
                </Card>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 mt-2 col-lg-9">
                <h5>Accept the terms and conditions</h5>

                <Card
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                  }}
                >
                  <Checkbox onChange={onChangechecktime}>
                    The General terms and conditions apply to all of your
                    properties listed through HB extranet.
                  </Checkbox>
                </Card>
                <div className="float-end mt-3 mt-sm-3 mb-4">
                  <Button
                    type="primary"
                    style={{ minWidth: "100px", marginRight: "5px" }}
                  >
                    <NavLink to="/Profile">Previous</NavLink>
                  </Button>

                  <Button
                    type="primary"
                    className="ms-sm-3"
                    onClick={functionshow}
                    style={{ minWidth: "100px" }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          title="Error message"
          open={openmodal}
          className="error_msg"
          onCancel={handleCancel}
          footer={[
            <Button type="primary" onClick={handleCancel}>
              Close
            </Button>,
          ]}
          maskClosable={false}
        >
          <p> Please accept the terms & conditions </p>
        </Modal>
        <Modal
          title="Success message"
          className="success_msg"
          open={openmodalsuccess}
          onCancel={handleCancelsuccess}
          footer={[
            <Button type="primary" onClick={handleCancelsuccess}>
              Close
            </Button>,
          ]}
          maskClosable={false}
        >
          <p> Property updated successfully </p>
        </Modal>
        <Modal
          title="Error message"
          open={openerror}
          onCancel={handleCancelerror}
          footer={[
            <Button type="primary" onClick={handleCancelerror}>
              Close
            </Button>,
          ]}
          maskClosable={false}
        >
          <p> Property has been created already </p>
        </Modal>
        <Modal
          className="error_msg"
          title="Message"
          open={open}
          onCancel={handleCancelotp}
          footer={[
            <Button type="primary" onClick={handletimer}>
              Close
            </Button>,
          ]}
          maskClosable={false}
        >
          {errormessagearr}
        </Modal>
        <Modal
          className="verify_msg info_msg success_msg"
          title="Enter Your OTP"
          open={openotpverify}
          onCancel={handleCancelotp}
          footer={[
            <Button
              type="primary"
              style={{ float: "left" }}
              onClick={functionshow}
            >
              Resend OTP
            </Button>,
            <Button type="primary" onClick={verifyotpfunc}>
              Verify OTP
            </Button>,
            <Button type="primary" className="mt-2 mt-md-0" onClick={handleCancelotp}>
              Cancel
            </Button>,
          ]}
          maskClosable={false}
        >
          <p className="mb-2 mt-2">
            Your OTP has been sent to registered whatsapp no and email &nbsp;
                      <span className="m-red" style={{ fontSize: "20px" }}>
                        *
                      </span>: <br></br>({frontoff_mobileno}, {frontoff_mail_id})
          </p>
          <Input
            placeholder="OTP"
            className="mt-n1 mb-3 contact_Name otpfield mx-auto"
            name="contactName"
            style={{
              width: "200px",
            }}
            value={otp_value}
            onChange={(e) => {
              setvaluefunc(textNum(e));
              setotpvalidmsg("");
            }}
            onInput={propInput}
            maxLength={6}
          ></Input>

          {(otpvalidmsg === "Please enter valid OTP" ||
            otpvalidmsg === "Please enter OTP") && (
            <span
              style={{
                color: otpvalidmsg === "Valid" ? "green" : "red",
                fontWeight: "bold",
                marginLeft: "10px",
              }}
            >
              {otpvalidmsg}
            </span>
          )}

          <p className="mb-2">Updated by &nbsp;
                      <span className="m-red" style={{ fontSize: "20px" }}>
                        *
                      </span>: </p>
          <Input
            placeholder="Updated by"
            className="mt-n1 contact_Name otpfield mx-auto"
            name="contactName"
            style={{
              width: "200px",
            }}
            value={Updatedbyval}
            onChange={(e) => {
              setupdatebyval(e?.target?.value);
              setupdatedbyvalid("");
            }}
            onInput={propInput}
            maxLength={50}
          ></Input>
          {updatedbyvalid === "Please enter updated by" && (
            <span
              style={{
                color: "red",
                fontWeight: "bold",
                marginLeft: "10px",
              }}
            >
              {updatedbyvalid}
            </span>
          )}
        </Modal>
        <Modal
        className="error_msg"
          title="Error message"
          open={errormodel}
          onCancel={handleCancelerr}
          footer={[
            <Button type="primary" onClick={handleCancelerr}>
              Close
            </Button>,
          ]}
          maskClosable={false}
        >
          {/* <p> Something went wrong <Link to={`/${idforUpdation}`}>please try again</Link>. </p> */}
          <p>Something went wrong please try again </p>
          <p style={{ margin: "0px 100px 10px" }}>
            <b>Or</b>
          </p>
          <p>Contact HB with your property name and GSTIN</p>
        </Modal>
        
      </div>
    </div>
  );
}
